"use strict";
import "@materializecss/materialize";

// scrollspy for desktop menu
document.addEventListener("DOMContentLoaded", function () {
  var elems = document.querySelectorAll(".scrollspy");
  var instances = M.ScrollSpy.init(elems, {
    offsetTop: 400,
  });
});

// parallax effect
document.addEventListener("DOMContentLoaded", function () {
  var elems = document.querySelectorAll(".parallax");
  var instances = M.Parallax.init(elems);
});

// mobile navigation
document.addEventListener('DOMContentLoaded', function() {
  var elems = document.querySelectorAll('.sidenav');
  var instances = M.Sidenav.init(elems);
});

document.addEventListener('DOMContentLoaded', function() {
  var esf = document.querySelectorAll('img.esf')?.[0];
  esf.addEventListener('click', () => {
    var esf_container = document.querySelectorAll('div.container.esf')?.[0];
    if (esf_container.classList.contains('zero')) {
      esf_container.classList.remove('zero');
    } else {
      esf_container.classList.add('zero');
    }
  });
});